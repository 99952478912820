import React from "react"
import PageHeader from "../components/common/PageHeader"
import Layout from "../components/layout"
import NewsList from "../components/news/NewsList"

import Seo from "../components/seo"
export default function KalendariumPage() {
  return (
    <div className="">
      <Seo title="Kalendarium" />

      <Layout>
        <div className="px-4 lg:px-8 space-y-4 lg:space-y-8 py-4 lg:py-16 max-w-4xl mx-auto">
          <PageHeader text="Kalendarium" />
          <NewsList />
        </div>
      </Layout>
    </div>
  )
}

import React from "react"
import Link from "../common/Link"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function NewsList() {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          uri
          title
          excerpt
          content
          date(formatString: "YYYY.MM.DD")
          featuredImage {
            node {
              caption
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED

                    quality: 70
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
  const posts = data.allWpPost.nodes
  return (
    <div className=" ">
      <div className="grid  ">
        {posts.map((post, i) => {
          return <Post key={i} post={post} />
        })}
      </div>
    </div>
  )
}

function Post({ post }) {
  const { title, date, uri, featuredImage, content } = post
  const img = featuredImage?.node.localFile.childImageSharp
  return (
    <div className="w-full odd:bg-primary/10 even:bg-primary/5 py-4 lg:py-8 px-4 ">
      <div className="w-full space-y-4">
        <div className="">
          {/* <p className="date">{date}</p> */}
          <Link to={uri} className="hover:underline">
            <h2>{title}</h2>
          </Link>
        </div>
        {/* {featuredImage && (
            <div className="w-full">
              <GatsbyImage image={getImage(img)} className="w-full h-full" />
            </div>
          )} */}
        <div>
          <div
            className="wp-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>{" "}
      </div>
    </div>
  )
}
